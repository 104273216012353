<template>
  <div>
    <div class="mb-5">
      <label>
        Choose a Campaign <fluency-loader v-if="loadingLocations" dots color inline />
      </label>
      <div class="mad-lib-container">
        <basic-mad-lib-input :value="quickPromoteCampaignPlanId"
                             :type-ahead-options="campaignOptions"
                             require-option
                             placeholder="Select a Campaign"
                             :retain-value-on-focus="false"
                             @input="quickPromoteCampaignPlanId = $event; campaignSelectHandler($event)"></basic-mad-lib-input>
      </div>
    </div>
    <div class="mb-5">
      <label>Choose an Ad Set</label>
      <div class="mad-lib-container">
        <basic-mad-lib-input :value="quickPromoteAdGroupPlanId"
                             :type-ahead-options="adGroupOptions"
                             require-option
                             :retain-value-on-focus="false"
                             placeholder="Select an Ad Set"
                             @input="quickPromoteAdGroupPlanId = $event; adGroupSelectHandler($event)"></basic-mad-lib-input>
      </div>
    </div>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'

export default {
  name: 'promotedPostCampaignAdSetChooser',
  components: { BasicMadLibInput, FluencyLoader },
  props: {
    accountPlanId: {
      type: Number,
      required: true
    },
    horizontalLabels: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quickPromoteCampaign: null,
      quickPromoteCampaignPlanId: null,
      quickPromoteAdSet: null,
      quickPromoteAdGroupPlanId: null,
      promotedPostLocations: [],
      loadingLocations: false
    }
  },
  watch: {
    accountPlanId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.loadSuggestedPromotedPostLocations({ accountPlanId: newVal })
        }
      }
    }
  },
  computed: {
    adGroups () {
      if (this.quickPromoteCampaign) {
        return this.quickPromoteCampaign.adGroups
      } else {
        return this.promotedPostLocations.flatMap(campaign => campaign.adGroups).sort((a, b) => (a ? 1 : -1) - (b ? 1 : -1))
      }
    },
    adGroupOptions () {
      return this.adGroups.map(adGroup => ({ value: adGroup.adGroupPlanId, text: `${adGroup.adGroupName}${adGroup.preferred ? ' - recommended' : ''}` }))
    },
    campaignOptions () {
      return this.promotedPostLocations.map(campaign => ({ value: campaign.campaignPlanId, text: `${campaign.campaignName}${campaign.preferred ? ' - recommended' : ''}` }))
    }
  },
  methods: {
    async loadSuggestedPromotedPostLocations ({ accountPlanId }) {
      this.loadingLocations = true
      const locations = await this.$res.fetch.promotablePostSuggestedLocations({ accountPlanId })
      if (locations) {
        this.promotedPostLocations.splice(0, this.promotedPostLocations.length, ...locations)
      }
      this.loadingLocations = false
    },
    campaignSelectHandler (campaignPlanId) {
      // this is not a campaignPlan
      const recommendedCampaign = this.promotedPostLocations.find(l => l.campaignPlanId === campaignPlanId)
      this.quickPromoteCampaign = recommendedCampaign
      if (recommendedCampaign?.adGroups.length === 1) {
        this.quickPromoteAdSet = recommendedCampaign.adGroups[0]
      } else if (!recommendedCampaign) { // cleared
        this.quickPromoteAdSet = null
      }
      this.emitSelected()
    },
    adGroupSelectHandler (adGroupPlanId) {
      const recommendedAdGroup = this.adGroups.find(ag => ag.adGroupPlanId === adGroupPlanId)
      this.quickPromoteAdSet = recommendedAdGroup
      if (!this.quickPromoteCampaign && recommendedAdGroup) {
        const associatedCampaign = this.promotedPostLocations.find(campaign => campaign.adGroups.some(adGroup => adGroup === recommendedAdGroup))
        if (associatedCampaign) {
          this.quickPromoteCampaign = associatedCampaign
        }
      }
      this.emitSelected()
    },
    emitSelected () {
      this.$emit('selected', { campaign: this.quickPromoteCampaign, adSet: this.quickPromoteAdSet })
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
